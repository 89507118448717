// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "23.5.0",
  "buildNumber": "18179",
  "gitSha": "7702b283accd1f90f014f0087aa2e9bd8baf4a97",
  "fullVersion": "23.5.0-18179",
  "formattedVersion": "23.5.0 (18179-7702b283accd1f90f014f0087aa2e9bd8baf4a97)",
  "copyrightYear": "2023"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/23.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/23.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
